<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('knowledge.library.overview')" class="catalog">
    <CCol cols="12" lg="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
              {{$t('knowledge.Library')}}
            </CCol>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
              <loadingSpinner v-if="videosUploading.length > 0" mode="inline" :content="$t('upload.Uploading_videos')" class="uploading_videos"/>
                
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <b-switch v-if="activeView === 'catalog'" class="mt-0 mb-0 mr-2" v-model="itemFocus" size="is-small">{{ $t('knowledge.Focus_on_items') }}</b-switch>
                <CButtonGroup>
                  <CButton color="outline-secondary" v-for="(value, key) in ['catalog', 'items']" :key="key" :pressed="value === activeView ? true : false" @click="activeView = value; getLibraryData();">
                    <i v-if="value === 'catalog'" class="fas fa-columns"></i>
                    <i v-if="value === 'items'" class="fas fa-list"></i>                    
                  </CButton>
                </CButtonGroup>               
              </div>
            </CCol> 
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow class="m-0">
            <CCol v-if="activeView === 'catalog' && !itemFocus" cols="4" lg="4" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span class="text-uppercase">
                      <b>{{$t('common.Categories')}}</b> 
                      <!-- <b-tag v-if="categories.length > 0" class="info ml-2">{{categories.length}}</b-tag> -->
                    </span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('knowledge.library.addcategory')" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link" color="primary" @click="openSidebarRight('knowledge_category_details', { kb_intent_category_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('kb.Add_category')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !categoriesLoading}">
                    <div v-if="categoriesLoading">
                      <loadingSpinner mode="auto" v-if="categoriesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="categoriesTable"
                              class="data_table add_bottom_left_radius"
                              :data="categories"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="categoriesPaginated"
                              :per-page="categoriesPerPage"
                              :current-page.sync="categoriesPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              :row-class="(row, index) => row.kb_intent_category_id_external === activeCategory.kb_intent_category_id_external && 'active'">
                            
                            <template slot-scope="props">                      
                              <b-table-column field="image" label="" width="2.5%">
                                <div v-if="props.row.category_image_id && props.row.from_marketplace === 'N'"
                                     class="item_image pointer"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/knowledge-category/' + props.row.kb_intent_category_id_external + '/' + props.row.category_image_id + '.jpg' + '/' + clientToken + ')' }"
                                     @click="getCategorySubcategories(props.row.kb_intent_category_name, props.row.kb_intent_category_id_external, props.row.from_marketplace);">
                                </div>
                                <div v-else-if="props.row.category_image_id && props.row.from_marketplace === 'Y'"
                                     class="item_image pointer"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/knowledge-category-mp/' + props.row.kb_intent_category_id_external + '/' + props.row.category_image_id + '.jpg' + '/' + clientToken + ')' }"
                                     @click="getCategorySubcategories(props.row.kb_intent_category_name, props.row.kb_intent_category_id_external, props.row.from_marketplace);">
                                </div>
                                <div v-else class="item_icon d-inline-block align-middle pointer" @click="getCategorySubcategories(props.row.kb_intent_category_name, props.row.kb_intent_category_id_external, props.row.from_marketplace);">
                                  <i class="fas fa-list"/>               
                                </div>         
                              </b-table-column>            
                              <b-table-column field="kb_intent_category_name" :label="$t('common.Category')" :searchable="searchEnabled">
                                <span class="pointer" @click="getCategorySubcategories(props.row.kb_intent_category_name, props.row.kb_intent_category_id_external, props.row.from_marketplace);">                        
                                  <b>{{props.row.kb_intent_category_name}}</b>
                                </span>
                                <span class="d-block meta">
                                  {{props.row.nr_of_subcategories}} {{props.row.nr_of_subcategories !== 1 ? $t('common.subcategories') : $t('common.subcategory')}} |
                                  {{props.row.nr_of_items}} {{props.row.nr_of_items !== 1 ? $t('common.items') : $t('common.item')}}
                                </span>
                              </b-table-column>                                                            
                              <b-table-column field="target_groups" label="" class="text-right" width="10%">
                                <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                                    targetGroupsType="knowledge category"
                                                    :targetGroupsIdExternal="props.row.kb_intent_category_id_external"
                                                    popoverPlacement="left"/>
                              </b-table-column>
                              <b-table-column field="action" width="10%" :visible="checkPermission('knowledge.library.editcategory')">
                                <div class="d-flex justify-content-lg-end">
                                  <CButton color="primary"
                                           class="m-0" 
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('common.Category_managed_by_owner')} : ''"
                                           @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('knowledge_category_details', { kb_intent_category_id_external: props.row.kb_intent_category_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('common.no_categories_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>            
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol v-if="activeView === 'catalog' && !itemFocus" cols="4" lg="4" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">                    
                    <span v-if="activeCategory.kb_intent_category_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeCategory.kb_intent_category_name}}</b>
                      <!-- <b-tag v-if="subcategories.length > 0" class="info ml-2">{{subcategories.length}}</b-tag> -->
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('common.Subcategories')}}</b></span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('knowledge.library.addsubcategory') && activeCategory.kb_intent_category_id_external && activeCategory.from_marketplace === 'N'" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link"
                               color="primary"
                               @click="openSidebarRight('knowledge_subcategory_details', { kb_intent_category: activeCategory, kb_intent_subcategory_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('kb.Add_subcategory')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="activeCategory.kb_intent_category_id_external">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !subcategoriesLoading}">
                    <div v-if="subcategoriesLoading">
                      <loadingSpinner mode="auto" v-if="subcategoriesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="subcategoriesTable"
                              class="data_table"
                              :data="subcategories"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="subcategoriesPaginated"
                              :per-page="subcategoriesPerPage"
                              :current-page.sync="subcategoriesPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              :row-class="(row, index) => row.kb_intent_subcategory_id_external === activeSubcategory.kb_intent_subcategory_id_external && 'active'">
                            
                            <template slot-scope="props">                              
                              <b-table-column field="image" label="" width="2.5%">
                                <div v-if="props.row.subcategory_image_id && props.row.from_marketplace === 'N'"
                                     class="item_image d-inline-block align-middle pointer"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/knowledge-subcategory/' + props.row.kb_intent_subcategory_id_external + '/' + props.row.subcategory_image_id + '.jpg' + '/' + clientToken + ')' }"
                                     @click="getSubcategoryItems(props.row.kb_intent_subcategory_name, props.row.kb_intent_subcategory_id_external, props.row.from_marketplace);">
                                </div>
                                <div v-else-if="props.row.subcategory_image_id && props.row.from_marketplace === 'Y'"
                                     class="item_image d-inline-block align-middle pointer"
                                     v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/knowledge-subcategory-mp/' + props.row.kb_intent_subcategory_id_external + '/' + props.row.subcategory_image_id + '.jpg' + '/' + clientToken + ')' }"
                                     @click="getSubcategoryItems(props.row.kb_intent_subcategory_name, props.row.kb_intent_subcategory_id_external, props.row.from_marketplace);">
                                </div>                                
                                <div v-else class="item_icon d-inline-block align-middle pointer" @click="getSubcategoryItems(props.row.kb_intent_subcategory_name, props.row.kb_intent_subcategory_id_external, props.row.from_marketplace);">
                                  <i class="fas fa-list"/>               
                                </div>                     
                              </b-table-column>
                              <b-table-column field="kb_intent_subcategory_name" :label="$t('common.Subcategory')" :searchable="searchEnabled">
                                <span class="pointer" @click="getSubcategoryItems(props.row.kb_intent_subcategory_name, props.row.kb_intent_subcategory_id_external, props.row.from_marketplace);">                        
                                  <b>{{props.row.kb_intent_subcategory_name}}</b>
                                </span>
                                <span class="d-block meta">
                                  {{props.row.nr_of_items}} {{props.row.nr_of_items !== 1 ? $t('common.items') : $t('common.item')}}
                                </span>                                
                              </b-table-column>                  
                              <b-table-column field="target_groups" label="" class="text-right" width="10%">
                                <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                                    targetGroupsType="knowledge subcategory"
                                                    :targetGroupsIdExternal="props.row.kb_intent_subcategory_id_external"
                                                    popoverPlacement="left"/>
                              </b-table-column>                  
                              <b-table-column field="action" width="10%" :visible="checkPermission('knowledge.library.editsubcategory')">
                                <div class="d-flex justify-content-lg-end">
                                  <CButton color="primary"
                                           class="m-0"
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('common.Subcategory_managed_by_owner')} : ''"
                                           @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('knowledge_subcategory_details', { kb_intent_category: props.row.category, kb_intent_subcategory_id_external: props.row.kb_intent_subcategory_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('common.no_subcategories_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>          
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('knowledge.Select_category_for_subcategories')}}</span>
                  </CCol>
                </CRow>          
              </div>
            </CCol>

            <CCol :cols="activeView === 'catalog' && !itemFocus ? 4 : 12" :lg="activeView === 'catalog' && !itemFocus ? 4 : 12" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span v-if="activeSubcategory.kb_intent_subcategory_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeSubcategory.kb_intent_subcategory_name}}</b>
                      <!-- <b-tag v-if="items.length > 0" class="info ml-2">{{items.length}}</b-tag> -->
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('common.Items')}}</b></span>                    
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('knowledge.library.additem') && activeSubcategory.kb_intent_subcategory_id_external && activeSubcategory.from_marketplace === 'N'" class="mt-1 mt-xl-0 d-inline-block align-top">                      
                      <CButton class="m-0 link"
                               color="primary"
                               @click="openSidebarRight('knowledge_item_details', { kb_intent_subcategory: activeView === 'catalog' ? activeSubcategory : null, kb_intent_id_external: null, back_to_preview: false })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('kb.Add_kb_item')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="(activeView === 'catalog' && activeSubcategory.kb_intent_subcategory_id_external) || activeView === 'items'">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !itemsLoading}">
                    <div v-if="itemsLoading">
                      <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                    </div>
                    <div v-else>
                      <b-table ref="itemsTable"
                              class="data_table includes_dropdown add_bottom_right_radius"
                              :class="{'add_bottom_left_radius' : activeView === 'items'}"
                              :data="items"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="itemsPaginated"
                              :per-page="itemsPerPage"
                              :current-page.sync="itemsPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize">
                            
                            <template slot-scope="props">
                              <!-- <b-table-column field="code" :label="$t('kb.code')" :searchable="searchEnabled" class="break">
                                {{props.row.code}}
                              </b-table-column> -->
                              <b-table-column field="item_title" :label="$t('common.Title')" :searchable="searchEnabled" class="break">
                                <span class="pointer" @click="openSidebarRight('knowledge_item_preview', { kb_intent_id_external: props.row.kb_intent_id_external })">{{props.row.item_title ? props.row.item_title : '-'}}</span>
                              </b-table-column>
                              <b-table-column field="subcategory.label" :visible="activeView === 'items' || itemFocus" :label="$t('common.Subcategory')" :searchable="searchEnabled" width="25%">
                                <span>{{props.row.subcategory.label}}</span>
                              </b-table-column>
                              <b-table-column field="item_evaluation_date" :visible="activeView === 'items' || itemFocus" :label="$t('kb.Evaluation_date')" :searchable="searchEnabled" width="15%">
                                <span v-if="props.row.item_evaluation_date">{{props.row.item_evaluation_date | moment('YYYY-MM-DD')}}</span>
                                <span v-else>-</span>
                              </b-table-column>
                              <b-table-column field="evaluator.name" :visible="activeView === 'items' || itemFocus" :label="$t('kb.Evaluator')" :searchable="searchEnabled" width="15%">
                                <span>{{props.row.evaluator ? props.row.evaluator.name : '-'}}</span>
                              </b-table-column>
                              <b-table-column field="item_status" :visible="activeView === 'items' || itemFocus" :label="$t('common.Status')" :searchable="searchEnabled" width="10%">
                                <span>{{props.row.item_status}}</span>
                              </b-table-column>
                              <b-table-column field="actions">
                                <div class="d-flex justify-content-lg-end">
                                  <CDropdown v-if="checkPermission('knowledge.insights.item') || checkPermission('knowledge.library.edititem')"
                                             color="primary"
                                             toggler-text=""
                                             :caret="false"
                                             class="ml-2 table_actions_dropdown">
                                    <div slot="toggler-content">
                                      <span class="d-flex align-items-center">
                                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                                      </span>
                                    </div>

                                    <CDropdownItem v-if="checkPermission('knowledge.insights.item')">
                                      <div class="d-flex align-items-center" @click="showItemInsights(props.row.kb_intent_id_external)">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="far fa-chart-bar"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('common.Show_insights')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                    <CDropdownItem v-if="checkPermission('knowledge.library.edititem')">
                                      <div class="d-flex align-items-center" 
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('common.Item_managed_by_owner')} : ''"
                                           @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('knowledge_item_details', { kb_intent_subcategory: activeView === 'catalog' ? props.row.subcategory : null, kb_intent_id_external: props.row.kb_intent_id_external, back_to_preview: false }) : null">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fas fa-pen"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('kb.edit_item')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                  </CDropdown>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">                                                          
                                <span>{{$t('common.no_items_found')}}</span>             
                              </div>                
                            </template> 
                      </b-table>
                    </div>
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('knowledge.Select_subcategory_for_items')}}</span>
                  </CCol>
                </CRow>           
              </div>
            </CCol>        
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>       
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';
import targetGroupPopover from '@/components/common/targetGroupPopover.vue'

export default {
  name: 'Library',
  components: {
    loadingSpinner,
    noPermission,
    targetGroupPopover
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,      
      activeView: 'catalog',
      itemFocus: false,
      activeCategory: {
        kb_intent_category_name: null,
        kb_intent_category_id_external: null,
        from_marketplace: null
      },
      activeSubcategory: {
        kb_intent_subcategory_name: null,
        kb_intent_subcategory_id_external: null,
        from_marketplace: null
      },
      categories: [],
      categoriesLoading: false,
      categoriesPaginated: false,
      categoriesPerPage: 8,
      categoriesPage: 1,
      subcategories: [],
      subcategoriesLoading: false,
      subcategoriesPaginated: false,
      subcategoriesPerPage: 8,
      subcategoriesPage: 1,
      items: [],
      itemsLoading: false,
      itemsPaginated: false,
      itemsPerPage: 8,
      itemsPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: true,
      videosUploading: []
    }
  },
  methods: {
    getLibraryData() {
      // Clear the data
      this.categories = [];         
      this.subcategories = [];      
      this.items = [];
      // Update the active data
      this.activeCategory = { kb_intent_category_name: null, kb_intent_category_id_external: null, from_marketplace: null };
      this.activeSubcategory = { kb_intent_subcategory_name: null, kb_intent_subcategory_id_external: null, from_marketplace: null };
      // Get the categories of items based on the activeView
      if(this.activeView === 'catalog') {
        this.getCategories();        
        this.categoriesPage = 1;
        this.itemFocus = false;
      } else {
        this.getItems();
        this.itemsPage = 1;
      }
    },
    getItems() {
      // Start the loader
      this.itemsLoading = true;
      // Get the items
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/items/overview')
      .then(res => {  
        this.items = res.data.data;
        // Check if the pagination should be enabled
        (this.items.length > this.itemsPerPage) ? this.itemsPaginated = true : this.itemsPaginated = false;
        // Stop the loader
        this.itemsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getItemDetails(itemIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/item/' + itemIdExternal)
      .then(res => {
        let updatedItem = res.data.data;

        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.itemsTable !== undefined ) {
          let itemIndex = this.$refs.itemsTable.data.findIndex(x => x.kb_intent_id_external == updatedItem.kb_intent_id_external);

          this.$refs.itemsTable.data[itemIndex].code = updatedItem.code;
          this.$refs.itemsTable.data[itemIndex].item_title = updatedItem.item_title;
          this.$refs.itemsTable.data[itemIndex].item_description = updatedItem.item_description;
          this.$refs.itemsTable.data[itemIndex].subcategory = updatedItem.subcategory;
          this.$refs.itemsTable.data[itemIndex].evaluator = updatedItem.evaluator;
          this.$refs.itemsTable.data[itemIndex].item_evaluation_date = updatedItem.item_evaluation_date;
          this.$refs.itemsTable.data[itemIndex].item_status_id = updatedItem.item_status_id;        
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },      
    getCategories () {
      // Start the loader
      this.categoriesLoading = true;
      this.categoriesPaginated = false;
      // Get the categories
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/categories/overview')
      .then(res => {
        this.categories = res.data.data;
        // Check if the pagination should be enabled
        (this.categories.length > this.categoriesPerPage) ? this.categoriesPaginated = true : this.categoriesPaginated = false;
        // Stop the loader
        this.categoriesLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getCategorySubcategories(categoryName, categoryIdExternal, categoryFromMarketplace) {
      // Check if a different category is selected
      if(this.activeCategory.kb_intent_category_id_external !== categoryIdExternal) {
        // Start the loader
        this.subcategoriesLoading = true;      
        // Clear the subcategories (if necessary) and items            
        this.subcategories = [];
        this.items = [];
        // Update the active data
        this.activeCategory = { kb_intent_category_name: categoryName, kb_intent_category_id_external: categoryIdExternal, from_marketplace: categoryFromMarketplace };
        this.activeSubcategory = { kb_intent_subcategory_name: null, kb_intent_subcategory_id_external: null, from_marketplace: null };
      }
      // Get the subcategories
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/category/' + categoryIdExternal + '/subcategories')
      .then(res => {
        this.subcategories = res.data.data;
        // Enable the pagination if necessary
        (this.subcategories.length > this.subcategoriesPerPage) ? this.subcategoriesPaginated = true : this.subcategoriesPaginated = false;        
        // Stop the loader
        this.subcategoriesLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getSubcategoryItems(subcategoryName, subcategoryIdExternal, subcategoryFromMarketplace) {
      // Check if a different subcategory is selected
      if(this.activeSubcategory.kb_intent_subcategory_id_external !== subcategoryIdExternal) {
        // Start the loader
        this.itemsLoading = true;
        // Clear the items
        this.items = [];
        // Update the active data
        this.activeSubcategory = { kb_intent_subcategory_name: subcategoryName, kb_intent_subcategory_id_external: subcategoryIdExternal, from_marketplace: subcategoryFromMarketplace };
      }
      // Get the items
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/subcategory/' + subcategoryIdExternal + '/items')
      .then(res => {
        this.items = res.data.data;
        // Enable the pagination if necessary
        (this.items.length > this.itemsPerPage) ? this.itemsPaginated = true : this.itemsPaginated = false;
        // Stop the loader
        this.itemsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    showItemInsights(id) {
      this.$router.push({path: `/insights/knowledge/item/${id.toString()}`});
    },    
    copyToClipboard(data) {
      // Set path
      let kbItemPath = '/kb/category/' + data.kb_intent_category_id_external + '/subcategory/' + data.kb_intent_subcategory_id_external + '/item/' + data.kb_intent_id_external;
      // Create temporary textarea for copy
      var copy = document.createElement("textarea");
      document.body.appendChild(copy);
      copy.value = kbItemPath;
      copy.select();
      document.execCommand("copy");
      document.body.removeChild(copy);
      // Show message
      this.$buefy.toast.open({ message: this.$t('common.Copied_path_to_clipboard'), type: 'is-success', duration: 2000 });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.getPlatformPermissions();
    this.getCategories();

    this.$bus.$on('update_kb_categories', () => {
      this.getCategories();
    });
    
    this.$bus.$on('update_kb_category_subcategories', (category) => {
      this.getCategorySubcategories(category.kb_intent_category_name, category.kb_intent_category_id_external, category.from_marketplace);
    });
    
    this.$bus.$on('update_kb_subcategory_items', (subcategory) => {
      this.getSubcategoryItems(subcategory.kb_intent_subcategory_name, subcategory.kb_intent_subcategory_id_external, subcategory.from_marketplace);
    });
    
    this.$bus.$on('update_kb_item', (itemIdExternal) => {
      if(this.activeView === 'items') this.getItemDetails(itemIdExternal);
    });
    
    this.$bus.$on('new_knowledge_video_uploading', (videoExternalId) => {
      // Add the external video id to the videosUploading array
      this.videosUploading.push(videoExternalId);
    })
    
    this.$bus.$on('knowledge_video_upload_finished', (videoExternalId) => {
      setTimeout(function() {
        var videoIndex = this.videosUploading.indexOf(videoExternalId);
        if(videoIndex >= 0) this.videosUploading.splice(videoIndex, 1);
      }.bind(this), 500);
    })    
  },
  beforeDestroy() {
    this.$bus.$off('update_kb_categories');
    this.$bus.$off('update_kb_category_subcategories');
    this.$bus.$off('update_kb_subcategory_items');
    this.$bus.$off('update_kb_item');    
  }  
}
</script>